import React from "react"
import InnerHTML from "dangerously-set-html-content"
import * as S from "./store-locator-map.styles.jsx"

const StoreLocatorMap = data => {
  const inlineScript = `
        <script>
        (() => { 
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.async = !0;
            a.src = "https://cdn.storepoint.co/api/v1/js/1612e496b5e59f.js";
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b);
        })();
        </script>
    `
  return (
    <S.Wrapper scrollSnap={data.scrollSnap}>
      <div id="storepoint-container" data-map-id="1612e496b5e59f"></div>
      <InnerHTML html={inlineScript} />
    </S.Wrapper>
  )
}
export default StoreLocatorMap
